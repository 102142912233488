import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';

export type authStateType = {
  forgotPasswordUser: '';
  createUserEmail: '';
  isResetPasswordSuccess: boolean;
  loginType: string;
};

const initialState: authStateType = {
  forgotPasswordUser: '',
  createUserEmail: '',
  isResetPasswordSuccess: false,
  loginType: ''
};

export const authStateSlice = createSlice({
  name: 'authStateSlice',
  initialState,
  reducers: {
    setUsername: (state, action) => {
      state.forgotPasswordUser = action.payload.forgotPasswordUser;
      return state;
    },
    setCreateUserEmail: (state, action) => {
      state.createUserEmail = action.payload.createUserEmail;
      return state;
    },
    setResetPasswordSuccess: (state, action) => {
      state.isResetPasswordSuccess = action.payload.isResetPasswordSuccess;
      return state;
    },
    setLoginType: (state, action) => {
      state.loginType = action.payload.loginType;
      return;
    }
  }
});

export const {
  setUsername,
  setCreateUserEmail,
  setResetPasswordSuccess,
  setLoginType
} = authStateSlice.actions;
export const getUsername = (state: RootState) =>
  state.authStateSlice.forgotPasswordUser;
export const getCreateUserEmail = (state: RootState) =>
  state.authStateSlice.createUserEmail;
export const getResetPasswordSuccess = (state: RootState) =>
  state.authStateSlice.isResetPasswordSuccess;
export const getLoginType = (state: RootState) =>
  state.authStateSlice.loginType;
