import { countryApi as api } from './countryApi';
export const addTagTypes = ['region', 'user'] as const;
export const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: build => ({
      regionCountryList: build.query<
        RegionCountryListApiResponse,
        RegionCountryListApiArg
      >({
        query: queryArg => ({
          url: `/region/country/`,
          params: {
            search: queryArg.search,
            limit: queryArg.limit,
            offset: queryArg.offset
          }
        }),
        providesTags: ['region']
      }),
      regionCountryCreate: build.mutation<
        RegionCountryCreateApiResponse,
        RegionCountryCreateApiArg
      >({
        query: queryArg => ({
          url: `/region/country/`,
          method: 'POST',
          body: queryArg.country
        }),
        invalidatesTags: ['region']
      }),
      regionCountryDetailedList: build.query<
        RegionCountryDetailedListApiResponse,
        RegionCountryDetailedListApiArg
      >({
        query: queryArg => ({
          url: `/region/country/detailed/`,
          params: {
            search: queryArg.search,
            limit: queryArg.limit,
            offset: queryArg.offset,
            ordering: queryArg.ordering
          }
        }),
        providesTags: ['region']
      }),
      regionCountryDetailedCreate: build.mutation<
        RegionCountryDetailedCreateApiResponse,
        RegionCountryDetailedCreateApiArg
      >({
        query: queryArg => ({
          url: `/region/country/detailed_create/`,
          method: 'POST',
          body: queryArg.country
        }),
        invalidatesTags: ['region']
      }),
      regionCountryUpdate: build.mutation<
        RegionCountryUpdateApiResponse,
        RegionCountryUpdateApiArg
      >({
        query: queryArg => ({
          url: `/region/country/${queryArg.id}/`,
          method: 'PUT',
          body: queryArg.country
        }),
        invalidatesTags: ['region']
      }),
      regionCountryDelete: build.mutation<
        RegionCountryDeleteApiResponse,
        RegionCountryDeleteApiArg
      >({
        query: queryArg => ({
          url: `/region/country/${queryArg.id}/`,
          method: 'DELETE'
        }),
        invalidatesTags: ['region']
      }),
      regionCountryDetailedInstance: build.query<
        RegionCountryDetailedInstanceApiResponse,
        RegionCountryDetailedInstanceApiArg
      >({
        query: queryArg => ({
          url: `/region/country/${queryArg.id}/detailed/`
        }),
        providesTags: ['region']
      }),
      regionCountrySeedList: build.query<
        RegionCountrySeedListApiResponse,
        RegionCountrySeedListApiArg
      >({
        query: queryArg => ({
          url: `/region/country_seed/`,
          params: {
            search: queryArg.search,
            limit: queryArg.limit,
            offset: queryArg.offset
          }
        }),
        providesTags: ['region']
      }),
      regionCountrySeedCreate: build.mutation<
        RegionCountrySeedCreateApiResponse,
        RegionCountrySeedCreateApiArg
      >({
        query: queryArg => ({
          url: `/region/country_seed/`,
          method: 'POST',
          body: queryArg.countrySeed
        }),
        invalidatesTags: ['region']
      }),
      regionTimezoneList: build.query<
        RegionTimezoneListApiResponse,
        RegionTimezoneListApiArg
      >({
        query: queryArg => ({
          url: `/region/timezone/`,
          params: {
            search: queryArg.search,
            limit: queryArg.limit,
            offset: queryArg.offset
          }
        }),
        providesTags: ['region']
      }),
      userUsersList: build.query<UserUsersListApiResponse, UserUsersListApiArg>(
        {
          query: queryArg => ({
            url: `/user/users/`,
            params: {
              search: queryArg.search,
              limit: queryArg.limit,
              offset: queryArg.offset
            }
          }),
          providesTags: ['user']
        }
      )
    }),
    overrideExisting: false
  });
export { injectedRtkApi as enhancedApi };
export type RegionCountryListApiResponse = /** status 200  */ {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: CountryRead[];
};
export type RegionCountryListApiArg = {
  /** A search term. */
  search?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type RegionCountryCreateApiResponse = /** status 201  */ CountryRead;
export type RegionCountryCreateApiArg = {
  country: Country;
};
export type RegionCountryDetailedListApiResponse = /** status 200  */ {
  count: number;
  next?: string | null;
  previous?: string | null;
  results?: CountryRead[];
};
export type RegionCountryDetailedListApiArg = {
  /** A search term. */
  search?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  ordering?: string;
};
export type RegionCountryDetailedCreateApiResponse =
  /** status 201  */ CountryRead;
export type RegionCountryDetailedCreateApiArg = {
  country: Country;
};
export type RegionCountryUpdateApiResponse = /** status 200  */ CountryRead;
export type RegionCountryUpdateApiArg = {
  /** A unique integer value identifying this country. */
  id: number;
  country: Country;
};
export type RegionCountryDeleteApiResponse = unknown;
export type RegionCountryDeleteApiArg = {
  /** A unique integer value identifying this country. */
  id: number;
};
export type RegionCountryDetailedInstanceApiResponse =
  /** status 200  */ CountryRead;
export type RegionCountryDetailedInstanceApiArg = {
  /** A unique integer value identifying this country. */
  id: number;
};
export type RegionCountrySeedListApiResponse = /** status 200  */ {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: CountrySeedRead[];
};
export type RegionCountrySeedListApiArg = {
  /** A search term. */
  search?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type RegionCountrySeedCreateApiResponse =
  /** status 201  */ CountrySeedRead;
export type RegionCountrySeedCreateApiArg = {
  countrySeed: CountrySeed;
};
export type RegionTimezoneListApiResponse = /** status 200  */ {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: TimezoneRead[];
};
export type RegionTimezoneListApiArg = {
  /** A search term. */
  search?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type UserUsersListApiResponse = /** status 200  */ {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: UserRead[];
};
export type UserUsersListApiArg = {
  /** A search term. */
  search?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type Country = {
  name?: string | null;
  co: string;
  ordering: string;
  director?: number[];
  timezone?: number[];
};
export type CountryRead = {
  id?: number;
  name?: string | null;
  co: string;
  ordering: string;
  director: number[];
  timezone: any;
  state: any;
};
export type CountrySeed = {
  name?: string | null;
  co: string;
};
export type Timezone = {
  name?: string | null;
  offset?: string | null;
  country?: number | null;
};
export type TimezoneRead = {
  id?: number;
  name?: string | null;
  offset?: string | null;
  country?: number | null;
};
export type CountrySeedRead = {
  name?: string | null;
  co: string;
  timezone?: TimezoneRead[];
};
export type User = {
  first_name?: string;
  last_name?: string;
  username: string;
  email: string;
  middle_name?: string | null;
};
export type UserRead = {
  id?: number;
  password?: string;
  last_login?: string | null;
  is_superuser?: boolean;
  first_name?: string;
  last_name?: string;
  is_staff?: boolean;
  is_active?: boolean;
  date_joined?: string;
  username: string;
  email: string;
  middle_name?: string | null;
  user_permissions?: number[];
  groups?: number[];
};
export const {
  useRegionCountryListQuery,
  useRegionCountryCreateMutation,
  useRegionCountryDetailedListQuery,
  useRegionCountryDetailedCreateMutation,
  useRegionCountryUpdateMutation,
  useRegionCountryDeleteMutation,
  useRegionCountryDetailedInstanceQuery,
  useRegionCountrySeedListQuery,
  useRegionCountrySeedCreateMutation,
  useRegionTimezoneListQuery,
  useUserUsersListQuery
} = injectedRtkApi;
