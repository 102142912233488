import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';

import { authApi } from '@ports/internal-services/auth/authApi';
import { notificationApi } from '@ports/internal-services/notification/notificationApi';
import { countryApi } from '@ports/internal-services/country/countryApi';
import { fileExportApi } from '@ports/internal-services/fileExport/fileExportApi';
import { rootReducer, rootPersistConfig } from './reducer';

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      },
      immutableCheck: false
    })
      .concat(authApi.middleware)
      .concat(notificationApi.middleware)
      .concat(countryApi.middleware)
      .concat(fileExportApi.middleware)
});

export const persister = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
