import '@business/assets/css/variables.css';
import '@business/assets/css/theme.css';
import '@business/assets/css/schedule.css';
import '@business/assets/css/login-styles.css';
import '@business/assets/css/header.css';
import '@business/assets/css/profile.css';
import '@business/assets/css/content-management.css';
import '@business/assets/css/area-creation.css';
import { Amplify } from 'aws-amplify';
import { lazy, Suspense, useEffect } from 'react';
import { getMessaging, onMessage } from 'firebase/messaging';
import firebaseApp from '@config/firebase';
import { ComponentProvider } from '@business/workflows/ComponentHigherOrder';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import amplifyconfig from '../amplifyconfiguration.json';
import { AppLoaderLay } from '@business/workflows/ComponentUI/AppLoaderLazy';
import { Helmet } from 'react-helmet';
import { t } from 'i18next';
import { dotenv } from '@utils/constants/env';

export const App = () => {
  Amplify.configure(amplifyconfig);
  useEffect(() => {
    if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
      const messaging = getMessaging(firebaseApp);
      const unsubscribe = onMessage(messaging, payload => {});
      return () => {
        unsubscribe();
      };
    }
    return () => {};
  }, [firebaseApp]);
  const AreaComponent = lazy(
    () =>
      import('@business/workflows/ComponentViews/AreaComponent/AreaComponent')
  );
  const Contacts = lazy(
    () => import('@business/workflows/ComponentViews/Contacts/Contacts')
  );

  const CountryList = lazy(
    () =>
      import('@business/workflows/ComponentViews/CountryCreation/CountryList')
  );
  const CountryDetails = lazy(
    () =>
      import(
        '@business/workflows/ComponentViews/CountryCreation/CountryDetails'
      )
  );
  const AddNewCountry = lazy(
    () =>
      import('@business/workflows/ComponentViews/CountryCreation/AddNewCountry')
  );
  const CreateAccount = lazy(
    () => import('@business/workflows/ComponentViews/SignUp/CreateAccount')
  );
  const UserCreation = lazy(
    () => import('@business/workflows/ComponentViews/UserCreation/UserCreation')
  );
  const Dashboard = lazy(
    () => import('@business/workflows/ComponentViews/Dashboard/Dashboard')
  );
  const District = lazy(
    () => import('@business/workflows/ComponentViews/District/District')
  );
  const Events = lazy(
    () => import('@business/workflows/ComponentViews/Events/Events')
  );
  const FindMatch = lazy(
    () => import('@business/workflows/ComponentViews/FindMatch/FindMatch')
  );
  const ForgotPassword = lazy(
    () =>
      import('@business/workflows/ComponentViews/ForgotPassword/ForgotPassword')
  );
  const Gallery = lazy(
    () => import('@business/workflows/ComponentViews/Gallery/Gallery')
  );
  const Home = lazy(
    () => import('@business/workflows/ComponentViews/Home/Home')
  );
  const RequestorInformation = lazy(
    () =>
      import(
        '@business/workflows/ComponentViews/RequestorInformation/RequestorInformation'
      )
  );
  const AreaCreation = lazy(
    () => import('@business/workflows/ComponentViews/AreaCreation/AreaCreation')
  );
  const Matches = lazy(
    () => import('@business/workflows/ComponentViews/Matches/Matches')
  );
  const MyPreferences = lazy(
    () =>
      import('@business/workflows/ComponentViews/MyPreferences/MyPreferences')
  );
  const Profile = lazy(
    () => import('@business/workflows/ComponentViews/Profile/Profile')
  );
  const MealRequests = lazy(
    () => import('@business/workflows/ComponentViews/MealRequests/MealRequests')
  );
  const ResetPassword = lazy(
    () =>
      import('@business/workflows/ComponentViews/ResetPassword/ResetPassword')
  );
  const PlayVideo = lazy(
    () => import('@business/workflows/ComponentViews/PlayVideo')
  );
  const Schedules = lazy(
    () => import('@business/workflows/ComponentViews/Schedules/Schedules')
  );
  const SignIn = lazy(
    () => import('@business/workflows/ComponentViews/SignIn/SignIn')
  );
  const SignUpStepOne = lazy(
    () => import('@business/workflows/ComponentViews/SignUp/SignUpStepOne')
  );
  const SignUpComplete = lazy(
    () => import('@business/workflows/ComponentViews/SignUp/SignUpComplete')
  );
  const SignUpStepFour = lazy(
    () => import('@business/workflows/ComponentViews/SignUp/SignUpStepFour')
  );
  const SignUpStepThree = lazy(
    () => import('@business/workflows/ComponentViews/SignUp/SignUpStepThree')
  );
  const SignUpStepTwo = lazy(
    () => import('@business/workflows/ComponentViews/SignUp/SignUpStepTwo')
  );
  const Training = lazy(
    () => import('@business/workflows/ComponentViews/Training/Training')
  );
  const Volunteer = lazy(
    () => import('@business/workflows/ComponentViews/Volunteer/Volunteer')
  );
  const router = createBrowserRouter([
    {
      path: 'area-creation',
      element: (
        <ComponentProvider>
          <AreaComponent />
        </ComponentProvider>
      )
    },
    {
      path: 'contacts',
      element: (
        <ComponentProvider>
          <Contacts />
        </ComponentProvider>
      )
    },
    {
      path: 'country',
      element: (
        <ComponentProvider>
          <CountryList />
        </ComponentProvider>
      )
    },
    {
      path: 'country/view-more',
      element: (
        <ComponentProvider>
          <CountryDetails />
        </ComponentProvider>
      )
    },
    {
      path: 'country/add-country',
      element: (
        <ComponentProvider>
          <AddNewCountry />
        </ComponentProvider>
      )
    },
    {
      path: 'create-account',
      element: (
        <ComponentProvider>
          <CreateAccount />
        </ComponentProvider>
      )
    },
    {
      path: 'create-user',
      element: (
        <ComponentProvider>
          <UserCreation />
        </ComponentProvider>
      )
    },
    {
      path: 'dashboard',
      element: (
        <ComponentProvider>
          <Dashboard />
        </ComponentProvider>
      )
    },
    {
      path: 'district',
      element: (
        <ComponentProvider>
          <District />
        </ComponentProvider>
      )
    },
    {
      path: 'events',
      element: (
        <ComponentProvider>
          <Events />
        </ComponentProvider>
      )
    },
    {
      path: 'find-match',
      element: (
        <ComponentProvider>
          <FindMatch />
        </ComponentProvider>
      )
    },
    {
      path: 'forgot-password',
      element: (
        <ComponentProvider>
          <ForgotPassword />
        </ComponentProvider>
      )
    },
    {
      path: 'gallery',
      element: (
        <ComponentProvider>
          <Gallery />
        </ComponentProvider>
      )
    },
    {
      path: 'home',
      element: (
        <ComponentProvider>
          {' '}
          <Home />
        </ComponentProvider>
      )
    },
    {
      path: 'information',
      element: (
        <ComponentProvider>
          <RequestorInformation isEdit />
        </ComponentProvider>
      )
    },
    {
      path: 'local-area',
      element: (
        <ComponentProvider>
          <AreaCreation />
        </ComponentProvider>
      )
    },
    {
      path: 'matches',
      element: (
        <ComponentProvider>
          <Matches />
        </ComponentProvider>
      )
    },
    {
      path: 'preference',
      element: (
        <ComponentProvider>
          <MyPreferences />
        </ComponentProvider>
      )
    },
    {
      path: 'profile',
      element: (
        <ComponentProvider>
          <Profile />
        </ComponentProvider>
      )
    },
    {
      path: 'request',
      element: (
        <ComponentProvider>
          <MealRequests />
        </ComponentProvider>
      )
    },
    {
      path: 'reset-password',
      element: (
        <ComponentProvider>
          <ResetPassword />
        </ComponentProvider>
      )
    },
    {
      path: 'resources',
      element: (
        <ComponentProvider>
          <PlayVideo />
        </ComponentProvider>
      )
    },
    {
      path: 'schedules',
      element: (
        <ComponentProvider>
          <Schedules />
        </ComponentProvider>
      )
    },
    {
      path: 'sign-in',
      element: (
        <ComponentProvider>
          <SignIn />
        </ComponentProvider>
      )
    },
    {
      path: 'sign-up',
      element: (
        <ComponentProvider>
          <SignUpStepOne />
        </ComponentProvider>
      )
    },
    {
      path: 'sign-up/complete',
      element: (
        <ComponentProvider>
          <SignUpComplete />
        </ComponentProvider>
      )
    },
    {
      path: 'sign-up/step-four',
      element: (
        <ComponentProvider>
          <SignUpStepFour />
        </ComponentProvider>
      )
    },
    {
      path: 'sign-up/step-three',
      element: (
        <ComponentProvider>
          <SignUpStepThree />
        </ComponentProvider>
      )
    },
    {
      path: 'sign-up/step-two',
      element: (
        <ComponentProvider>
          <SignUpStepTwo />
        </ComponentProvider>
      )
    },
    {
      path: 'training',
      element: (
        <ComponentProvider>
          <Training />
        </ComponentProvider>
      )
    },
    {
      path: 'volunteer',
      element: (
        <ComponentProvider>
          <Volunteer />
        </ComponentProvider>
      )
    },
    {
      path: '/',
      element: (
        <ComponentProvider>
          {' '}
          <SignIn />
        </ComponentProvider>
      )
    }
  ]);
  const appName = t('appName');
  const siteDescription = 'Best influencer PWA App in the world';
  return (
    <>
      <Helmet>
        <title>{appName}</title>
        <meta name="description" content="Generated by create next app" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="noindex" />

        <meta name="application-name" content={appName} />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content={appName} />
        <meta name="description" content={siteDescription} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="msapplication-config" content="/icons/browserconfig.xml" />
        <meta name="msapplication-TileColor" content="#2B5797" />
        <meta name="msapplication-tap-highlight" content="no" />
        <meta name="theme-color" content="#000000" />

        <link rel="apple-touch-icon" href="/icons/touch-icon-iphone.png" />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/icons/touch-icon-ipad.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/icons/touch-icon-iphone-retina.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="167x167"
          href="/icons/touch-icon-ipad-retina.png"
        />

        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/icons/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/icons/favicon-16x16.png"
        />
        <link rel="manifest" href="/manifest.json" />
        {/* <link
            rel="mask-icon"
            href="/icons/safari-pinned-tab.svg"
            color="#5bbad5"
          /> */}
        <link rel="shortcut icon" href="/favicon.png" />

        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:url"
          content={`http://${dotenv.FRONTEND_BASE_URL}`}
        />
        <meta name="twitter:title" content={appName} />
        <meta name="twitter:description" content={siteDescription} />
        <meta
          name="twitter:image"
          content={`https://${dotenv.FRONTEND_BASE_URL}/icons/android-chrome-192x192.png`}
        />
        <meta name="twitter:creator" content="@NaicoITS" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={appName} />
        <meta property="og:description" content={siteDescription} />
        <meta property="og:site_name" content={appName} />
        <meta
          property="og:url"
          content={`https://${dotenv.FRONTEND_BASE_URL}`}
        />
        <meta
          property="og:image"
          content={`https://${dotenv.FRONTEND_BASE_URL}/icons/touch-icon-iphone.png`}
        />

        <link
          rel="apple-touch-startup-image"
          href="/splash/splash_2048.png"
          sizes="2048x2732"
        />
        <link
          rel="apple-touch-startup-image"
          href="/splash/splash_1668.png"
          sizes="1668x2224"
        />
        <link
          rel="apple-touch-startup-image"
          href="/splash/splash_1536.png"
          sizes="1536x2048"
        />
        <link
          rel="apple-touch-startup-image"
          href="/splash/splash_1125.png"
          sizes="1125x2436"
        />
        <link
          rel="apple-touch-startup-image"
          href="/splash/splash_1242.png"
          sizes="1242x2208"
        />
        <link
          rel="apple-touch-startup-image"
          href="/splash/splash_750.png"
          sizes="750x1334"
        />
        <link
          rel="apple-touch-startup-image"
          href="/splash/splash_640.png"
          sizes="640x1136"
        />
      </Helmet>

      <Suspense fallback={<AppLoaderLay />}>
        <RouterProvider router={router} />
      </Suspense>
    </>
  );
};
