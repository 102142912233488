import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';

export type manualLoadingStateType = {
  isLoading: boolean;
};

const initialState: manualLoadingStateType = { isLoading: false };

export const manualLoadingStateSlice = createSlice({
  name: 'manualLoadingStateSlice',
  initialState,
  reducers: {
    setIsManualLoading: (state, action) => {
      state.isLoading = action.payload.isLoading;
      return state;
    }
  }
});

export const { setIsManualLoading } = manualLoadingStateSlice.actions;
export const getManualLoadingState = (state: RootState) =>
  state.manualLoadingStateSlice.isLoading;
