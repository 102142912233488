import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { notificationApi } from '@ports/internal-services/notification/notificationApi';
import { countryApi } from '@ports/internal-services/country/countryApi';
import { fileExportApi } from '@ports/internal-services/fileExport/fileExportApi';
import { authApi } from '../internal-services/auth/authApi';
import { sessionStateSlice } from './sessionStateSlice';
import { alertSlice } from './alertStateSlice';
import { appLoadingStateSlice } from './appLoadingStateSlice';
import { manualLoadingStateSlice } from './manualLoadingStateSlice';
import { countryStateSlice } from './countryDetailsSlice';
import { authStateSlice } from './authStateSlice';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-'
};

const rootReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [notificationApi.reducerPath]: notificationApi.reducer,
  [countryApi.reducerPath]: countryApi.reducer,
  [fileExportApi.reducerPath]: fileExportApi.reducer,
  [sessionStateSlice.name]: sessionStateSlice.reducer,
  [alertSlice.name]: alertSlice.reducer,
  [appLoadingStateSlice.name]: appLoadingStateSlice.reducer,
  [manualLoadingStateSlice.name]: manualLoadingStateSlice.reducer,
  [countryStateSlice.name]: countryStateSlice.reducer,
  [authStateSlice.name]: authStateSlice.reducer
});

export { rootPersistConfig, rootReducer };
