export const dotenv = {
  API_BASE_URL: 'https://api-user-dev.lasagnaloveportal.org/api/v1',
  NOTIFICATION_BASE_URL: '',
  FRONTEND_BASE_URL: 'dev.lasagnaloveportal.org',
  GOOGLE_MAP_KEY: "'AIzaSyBIksdddddjZr0p7okk7aQ8_g1329c',",
  VAPID:
    "'BEJX_6Zp8xcLvXzEw5PgsTPX-X7WwWDja6HqtH4m4hJWZ8SpiKrKhWstFYwaC7jvRzBE1YhLE8nNlpvzi6gzoGk',",
  APP_KEY: "'AIzaSyA6qGeMbJsETbX6m0D8fAC6a3q4GjWFOFc',",
  AUTH_DOMAIN: "'lasagna-love-notification.firebaseapp.com',",
  PROJECT_ID: "'lasagna-love-notification',",
  STORAGE_BUCKET: "'lasagna-love-notification.appspot.com',",
  MESSAGING_SENDER_ID: "'1046049751812',",
  APP_ID: "'1:1046049751812:web:943bc8e28409c9d6fcc651',",
  MEASUREMENT_ID: 'G-FR32JXLZ47',
  PRIVACY_POLICY:
    "'https://d3glndpx2lnf96.cloudfront.net/As+a+Lasagna+Love+volunteer+2023.pdf',",
  EMAIL_AGREEMENT:
    'https://d3glndpx2lnf96.cloudfront.net/Email_Correspondence_Agreement.pdf'
};
