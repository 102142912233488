import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ComponentProviderProps } from '@business/interfaces/component-provider';
import { routePaths } from '@utils/constants/routePaths';

/*
 * This component is used redirect the user to corresponding page if user is logged in
 */
export function RouteMiddleware({
  children
}: Readonly<ComponentProviderProps>) {
  const navigate = useNavigate();
  const [windowLocation, setWindowLocation] = useState<string>('');
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWindowLocation(window.location.href);
    }
  }, []);
  useEffect(() => {
    if (window.location.href.includes('#_=_')) {
      navigate(routePaths.SignUp);
    }
  }, [windowLocation]);

  return <>{children}</>;
}
