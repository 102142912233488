export const routePaths = {
  SignIn: '/sign-in',
  SignUp: '/sign-up',
  SignUpStepTwo: '/sign-up/step-two',
  SignUpStepThree: '/sign-up/step-three',
  SignUpStepFour: '/sign-up/step-four',
  SignUpComplete: '/sign-up/complete',
  Dashboard: '/dashboard',
  ForgotPassword: '/forgot-password',
  ResetPassword: '/reset-password',
  Training: '/training',
  Preferences: '/preference',
  Schedules: '/schedules',
  LocalAreaCreation: '/local-area',
  Matches: '/matches',
  Events: '/events',
  Volunteer: '/volunteer',
  Home: '/home',
  Profile: '/profile',
  Contacts: '/contacts',
  RequestorInformation: '/information',
  Gallery: '/gallery',
  MealRequests: '/request',
  FindMatch: '/find-match',
  Resources: '/resources',
  District: '/district',
  CreateUser: '/create-user',
  AreaCreation: '/area-creation',
  CountryList: '/country',
  CountryDetails: '/country/view-more',
  CreateAccount: '/create-account',
  CreateCountry: '/country/add-country'
};
