import { fetchAuthSession } from 'aws-amplify/auth';

export const getCognitoToken = async () => {
  try {
    const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};

    return {
      accessToken: accessToken?.toString(),
      idToken: idToken?.toString()
    };
  } catch (e: any) {
    console.log(e.message);
    return '';
  }
};
